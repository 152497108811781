import '@datadog/browser-logs'
import '@datadog/browser-rum'
import jquery from 'jquery'
import slick from 'slick-carousel'
import 'slick-carousel/slick/slick.css'
import cookies from 'js-cookie'
import hls from 'hls.js'
import Rails from 'rails-ujs'
import tlds from 'tlds'

window.jQuery = jquery
window.$ = jquery
window.Slick = slick
window.Cookies = cookies
window.Hls = hls
window.Tlds = tlds
Rails.start()
